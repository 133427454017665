<template>
  <v-row justify="center">
    <v-dialog v-model="modalStatus" class="mx-auto" persistent max-width="70%">
      <v-card outlined>
        <v-form
          ref="validForm"
          lazy-validation
        >
          <v-card-title>
            <v-icon left color="primary"> mdi-badge-account-horizontal </v-icon>
            <span class="text-h6"
              >Administrador do fornecedor - {{ provider.name }}</span
            >
            <v-spacer></v-spacer>
            <v-btn color="blue-grey darken-2" text @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="mb-4"></v-divider>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="col-12">
                  <v-text-field
                    v-model="form.adm.name"
                    label="Nome"
                    outlined
                    dense
                    :rules="nameRules"
                    required
                    :loading="loadingProvidersAdministrator"
                    name="administration-name"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="col-6">
                  <v-text-field
                    v-model="form.adm.cpf"
                    label="CPF do Administrador"
                    outlined
                    dense
                    :rules="cpfRules"
                    :loading="loadingProvidersAdministrator"
                    required
                    name="administration-cpf"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                    v-model="form.adm.rg"
                    label="RG do Administrador"
                    outlined
                    dense
                    :rules="rgRules"
                    :loading="loadingProvidersAdministrator"
                    required
                    name="administration-rg"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="col-6">
                  <v-text-field
                    v-model="form.adm.email"
                    label="E-mail"
                    :rules="emailRules"
                    :loading="loadingProvidersAdministrator"
                    outlined
                    dense
                    required
                    name="administration-email"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="col-6">
                  <v-text-field
                    v-model="form.adm.pass"
                    label="Senha Atendimento"
                    :rules="passRules"
                    :loading="loadingProvidersAdministrator"
                    :append-icon="passwordShowAdm ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordShowAdm ? 'text' : 'password'"
                    @click:append="passwordShowAdm = !passwordShowAdm"
                    outlined
                    dense
                    required
                    name="administration-pass"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-title>
            <v-icon left color="primary"> mdi-email-edit </v-icon>
            <span class="title-1">  Configurações do Email </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <v-container>
              <v-col class="col-3">
                <v-autocomplete
                  label="Provedor Serviço de E-mail"
                  v-model="form.service"
                  :items="config_email"
                  item-value="value"
                  item-text="name"
                  outlined
                  dense
                  :rules="[v => !!v || 'Obrigatório']"
                ></v-autocomplete>
              </v-col>

              <div v-if="form.service === 'microsoft'">
                <ProviderConfigMicrosoftComponent
                  :selected_config="form.email"
                  @save="saveConfigMicrosoft"
                />
              </div>

              <div v-if="form.service === 'webmail'">
                <div v-if="form.service === 'webmail'" class="d-flex">
                  <!-- Campo de E-mail -->
                  <v-col class="col-8" style="flex: 1;">
                    <v-text-field
                      label="E-mail"
                      outlined
                      prepend-inner-icon="mdi-email"
                      dense
                      v-model="form.email.email"
                      :loading="loadingProvidersAdministrator"
                      :rules="emailRules"
                      required
                      name="config-email"
                    />
                  </v-col>

                  <!-- Botão Regras de captura -->
                  <v-col class="col-4">
                    <v-btn style="width: 100%" class="primary" @click="showFilterRule = true">
                      Regras de captura
                    </v-btn>
                  </v-col>
                </div>

                <v-card elevation="0">
                  <v-card-title>
                    IMAP
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                        <v-text-field
                          label="IMAP"
                          outlined
                          prepend-inner-icon="mdi-email-fast"
                          append-icon=""
                          dense
                          v-model="form.email.imap_host"
                          :loading="loadingProvidersAdministrator"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-imap-host"
                        />
                      </v-col>
                      <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                        <v-text-field
                          label="IMAP Porta"
                          outlined
                          prepend-inner-icon="mdi-email-fast"
                          append-icon=""
                          dense
                          v-model="form.email.imap_port"
                          :loading="loadingProvidersAdministrator"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          type="number"
                          name="config-imap-port"
                        />
                      </v-col>
                      <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                        <v-text-field
                          label="IMAP Autenticação"
                          outlined
                          prepend-inner-icon="mdi-email-fast"
                          append-icon=""
                          dense
                          v-model="form.email.imap_encryption"
                          :loading="loadingProvidersAdministrator"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-imap-encryption"
                        />
                      </v-col>
                      <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                        <v-text-field
                          v-model="form.email.imap_password"
                          :append-icon="passwordShowImap ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordShowImap ? 'text' : 'password'"
                          @click:append="passwordShowImap = !passwordShowImap"
                          label="IMAP Senha"
                          outlined
                          :loading="loadingProvidersAdministrator"
                          dense
                          counter
                          required
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          name="config-imap-password"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card elevation="0">
                  <v-card-title>
                    SMTP
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                        <v-text-field
                          label="SMTP"
                          outlined
                          prepend-inner-icon="mdi-email-newsletter"
                          append-icon=""
                          dense
                          :loading="loadingProvidersAdministrator"
                          v-model="form.email.mail_host"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-smtp-host"
                        />
                      </v-col>
                      <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                        <v-text-field
                          label="SMTP Porta"
                          outlined
                          prepend-inner-icon="mdi-email-newsletter"
                          append-icon=""
                          dense
                          :loading="loadingProvidersAdministrator"
                          v-model="form.email.mail_port"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          type="number"
                          name="config-smtp-port"
                        />
                      </v-col>
                      <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                        <v-text-field
                          label="SMTP Autenticação"
                          outlined
                          prepend-inner-icon="mdi-email-newsletter"
                          append-icon=""
                          dense
                          :loading="loadingProvidersAdministrator"
                          v-model="form.email.mail_encryption"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-smtp-encryption"
                        />
                      </v-col>
                      <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                        <v-text-field
                          v-model="form.email.mail_password"
                          :append-icon="passwordShowSMTP ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordShowSMTP ? 'text' : 'password'"
                          label="SMTP Senha"
                          outlined
                          :loading="loadingProvidersAdministrator"
                          dense
                          counter
                          @click:append="passwordShowSMTP = !passwordShowSMTP"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-smtp-password"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <FilterRuleGetEmailsModal
                  v-if="showFilterRule"
                  :rules_provider="form.email.rules_get"
                  @change="changeRules"
                  @close="showFilterRule = false"
                ></FilterRuleGetEmailsModal>
              </div>

              <v-card elevation="3">
                <v-card-title>
                  Título da Notificação
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="12" cols="12">
                      <quill-editor
                        :content="this.form.email.notification_title"
                        :options="editorOption"
                        @change="onEditorChange($event, 'notification_title')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-title>
                  Rodapé da Notificação
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="12" cols="12">
                      <quill-editor
                        :content="this.form.email.notification_footer"
                        :options="editorOption"
                        @change="onEditorChange($event, 'notification_footer')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-title>
                  Assinatura e-mail fornecedor
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="12" cols="12">
                      <quill-editor
                        :content="this.form.email.email_signature"
                        :options="editorOption"
                        @change="onEditorChange($event, 'email_signature')"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="white" class="success" text @click="testConnection()" :loading="loadingProvidersAdministrator || loadingButton">
                  Testar conexão
                </v-btn>
                <v-btn color="white" class="primary" text @click="saveDialog()" :loading="loadingProvidersAdministrator || loadingButton">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import ProviderConfigMicrosoftComponent from "@/components/provider/ProviderConfigMicrosoftComponent.vue";
import FilterRuleGetEmailsModal from "@/components/provider/modal/FilterRuleGetEmailsModal.vue";
export default {
  props: ["provider"],
  components: {
    FilterRuleGetEmailsModal,
    ProviderConfigMicrosoftComponent,
    quillEditor
  },
  data() {
    return {
      showFilterRule: false,
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
      config_email: [
        {
          name: 'WebMail',
          value: 'webmail'
        },
        {
          name: 'Microsoft',
          value: 'microsoft'
        },
      ],
      disableFields: false,
      nameRules: [v => !!v || "Nome obrigatório"],
      passRules: [v => !!v || "Senha Obrigatoria"],
      cpfRules: [v => !!v || "CPF Obrigatorio"],
      rgRules: [v => !!v || "RG Obrigatorio"],
      passwordShowImap: false,
      passwordShowSMTP: false,
      passwordShowAdm: false,
      emailRules: [
        v => !!v || 'Campo é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail deve ser válido',
      ],
      form: {
        provider_id: "",
        service: "",
        adm: {
          id: null,
          name: "",
          email: "",
          pass: "",
          cpf: "",
          rg: "",
        },
        email: {
          id: null,
          email: undefined,
          imap_host: undefined,
          imap_port: undefined,
          imap_encryption: undefined,
          imap_password: undefined,
          mail_host: undefined,
          mail_port: undefined,
          mail_encryption: undefined,
          mail_password: undefined,
          default_connection: false,
          email_signature: "",
          notification_title: "",
          notification_footer: ""
        }
      },
      config_microsoft: {},
      creating: false,
      modalStatus: true,
      loadingProvidersAdministrator: true,
      search: "",
      loading: false,
      loadingButton: false,
    };
  },

  async mounted() {
    await this.loadManager(this.provider.id).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    if (this.administration_list) {
      this.form = this.administration_list;
    }
    this.loadingProvidersAdministrator = false;
  },

  computed: {
    ...mapState("provider", ["administration_list","administration_update"])
  },

  methods: {
    ...mapActions("configurationCompany", ["testConnectionEmail",]),
    ...mapActions("provider", ["loadManager", "deleteManager", "updateManager"]),

    testConnection: async function () {
      this.loadingButton = true;
      if (this.$refs.validForm.validate()) {
        this.form.provider_id = this.provider.id;
        let params = {};
        if (this.form.service === 'webmail') {
          params = this.form;
        } else if (this.form.service === 'microsoft') {
          params = {
            provider_id: this.form.provider_id,
            service: this.form.service,
            adm: this.form.adm,
            email: this.config_microsoft
          }
        } else {
          await this.$swal('Oops...', 'Ocorreu um erro. Contate o suporte.', "error");
          this.loadingButton = false;
          return;
        }
        try {
          await this.testConnectionEmail(params);
        } catch (e) {
          await this.$swal("Oops...", e.message, "error");
        }
      }
      this.loadingButton = false;
    },

    changeRules (data) {
      this.form.email.rules_get = data;
    },

    closeDialog() {
      this.modalStatus = false;
      this.$emit("showModal", this.modalStatus);
    },

    onEditorChange(value, field) {
      this.form.email[field] = value.html;
    },

    saveConfigMicrosoft (data) {
      this.config_microsoft = data;
    },

    saveDialog: async function () {
      this.loadingButton = true;
      if (this.$refs.validForm.validate()) {
        this.form.provider_id = this.provider.id;
        let params = {};
        if (this.form.service === 'webmail') {
          params = this.form;
        } else if (this.form.service === 'microsoft') {
          params = {
            provider_id: this.form.provider_id,
            service: this.form.service,
            adm: this.form.adm,
            email: this.config_microsoft
          }
        } else {
          await this.$swal('Oops...', 'Ocorreu um erro. Contate o suporte.', "error");
          this.loadingButton = false;
          return;
        }

        try {
          await this.updateManager(params);
        } catch (e) {
          await this.$swal("Oops...", e.message, "error");
          this.loadingButton = false;
          return;
        }

        if (this.administration_update.success) {
          await this.$swal({
            icon: "success",
            title: this.administration_update.message
          });
          this.closeDialog();
        }
      }
      this.loadingButton = false;
    },
  }
};
</script>
