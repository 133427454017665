<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="E-mail para login"
            v-model="config.account_email"
            outlined
            dense
            :loading="loading"
            :rules="emailRules"
            :readonly="readonly"
            @change="save"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="E-mail para envio"
            v-model="config.email_sender"
            outlined
            dense
            :loading="loading"
            :rules="emailRules"
            :readonly="readonly"
            @change="save"
          />
        </v-col>
        <v-col cols="4">
          <v-btn style="width: 100%" class="primary" @click="showFilterRule = true">
            Regras de captura
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Client ID"
            v-model="config.client_id"
            outlined
            dense
            :type="show_client_id ? 'text' : 'password'"
            :append-icon="show_client_id ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_client_id = !show_client_id"
            :loading="loading"
            :rules="default_rule"
            :readonly="readonly"
            @change="save"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Client Secret"
            v-model="config.client_secret"
            outlined
            dense
            :type="show_client_secret ? 'text' : 'password'"
            :append-icon="show_client_secret ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_client_secret = !show_client_secret"
            :loading="loading"
            :rules="default_rule"
            :readonly="readonly"
            @change="save"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Tenant ID"
            v-model="config.tenant_id"
            outlined
            dense
            :type="show_tenant_id ? 'text' : 'password'"
            :append-icon="show_tenant_id ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show_tenant_id = !show_tenant_id"
            :loading="loading"
            :rules="default_rule"
            :readonly="readonly"
            @change="save"
          />
        </v-col>
      </v-row>
    </v-container>
    <FilterRuleGetEmailsModal
      v-if="showFilterRule"
      :rules_provider="config.rules_get"
      @change="changeRules"
      @close="showFilterRule = false"
    ></FilterRuleGetEmailsModal>
  </div>
</template>

<script>

import FilterRuleGetEmailsModal from "@/components/provider/modal/FilterRuleGetEmailsModal.vue";

export default {
  components: { FilterRuleGetEmailsModal },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    selected_config: {
      type: [Object, Array],
      default: () => {}
    }
  },
  data() {
    return {
      showFilterRule: false,
      show_client_id: false,
      show_client_secret: false,
      show_tenant_id: false,
      default_rule: [v => !!v || 'Obrigatório'],
      emailRules: [
        v => !!v || 'Obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail deve ser válido',
      ],
      config: {
        account_email: '',
        email_sender: '',
        filter_email: '',
        tenant_id: '',
        client_id: '',
        client_secret: '',
        rules_get: []
      },
      loading: true,
    };
  },
  async mounted() {
    if (this.selected_config) {
      this.config = this.selected_config;
      this.save();
    }
    this.loading = false;
  },
  computed: {
  },
  methods: {
    save () {
      if (!this.readonly) {
        this.$emit("save", this.config);
      }
    },
    changeRules (data) {
      this.config.rules_get = data;
    },
  },
};
</script>
<style scoped></style>
