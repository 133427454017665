<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary">mdi-briefcase</v-icon
              >Fornecedores
            </h3>
            <v-spacer></v-spacer>
            <v-btn class="ml-3" color="primary" @click="newProvider">
              Adicionar fornecedor
            </v-btn>
          </v-card-title>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :loading="loadingProvider"
            :search="search"
            :headers="columns"
            :items="provider_list.data"
            locale="pt"
          >
            <template v-slot:item.send_email_provider="{ item }">
              <v-icon
                v-if="item.send_email_provider"
                class="mr-4"
                small
                color="success"
              >
                mdi-check-circle
              </v-icon>
              <v-icon
                v-else
                class="mr-4"
                small
                color="error"
              >
                mdi-close-circle
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mr-4"
                    small
                    @click="viewProviderAdministrator(item)"
                    v-on="on"
                  >
                    mdi-badge-account-horizontal
                  </v-icon>
                </template>
                <span>Administrador do Fornecedor</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mr-4"
                    small
                    @click="editProvider(item.id)"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon small @click="deleteProviderItem(item)" v-on="on">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <div>
            <ProviderAdministratorDialogComponent
              :provider="providerSelected"
              v-if="dialogAdministration === true"
              @showModal="changeStatus($event)"
              @showAddModal="showAddModal($event)"
              @showEditModal="showEditModal($event)"
              @getProviderId="getProviderId($event)"
              @editStatus="editStatus($event)"
            />
            <AddManager
              v-if="dialogAddManagerStatus === true"
              :selectedProviderId="selectedProviderId"
              :editStatusModal="editStatusModal"
              @showAddModal="showAddModal($event)"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddManager from "@/components/provider/AddManager";
import ProviderAdministratorDialogComponent from "@/components/provider/ProviderAdministratorDialogComponent";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    ProviderAdministratorDialogComponent,
    AddManager
  },
  data() {
    return {
      dialogAddManagerStatus: false,
      loadingProvider: true,
      search: "",
      dialogAdministration: false,
      providerSelected: {},
      editStatusModal: false,
      selectedProviderId: "",
      columns: [
        { text: "Nome", value: "name" },
        { text: "E-mail destinatário", value: "email" },
        { text: "Enviar e-mail destinatário?", align: "center", value: "send_email_provider" },
        { text: "E-mail remetente", value: "email_remetente" },
        { text: "Data de cadastro", value: "created_at_format" },
        { text: "Data de atualização", value: "updated_at_format" },
        { text: "", align: "end", value: "actions", sortable: false }
      ]
    };
  },
  async mounted() {
    await this.loadProviderList();
  },
  computed: {
    ...mapState("provider", ["provider_list", "provider_form"]),
    ...mapState("index", ["errorMessage"])
  },
  methods: {
    ...mapActions("provider", [
      "loadProvider",
      "saveProvider",
      "deleteProvider"
    ]),

    async loadProviderList() {
      this.loadingProvider = true;
      await this.loadProvider().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.loadingProvider = false;
    },

    newProvider() {
      this.$router.push({ name: "provider-new" });
    },
    editProvider(id_provider) {
      this.$router.push({ name: "provider-edit", params: { id: id_provider } });
    },
    async deleteProviderItem(provider) {
      this.loadingProvider = true;
      await this.$swal({
        icon: "warning",
        text: `Tem certeza que deseja excluir o fornecedor ${provider.name}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#445E93",
        confirmButtonText: "Sim, remover o fornecedor!"
      }).then(async result => {
        if (result.value) {
          await this.deleteProvider(provider).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
        }
      });
      this.loadingProvider = false;
    },
    viewProviderAdministrator(provider) {
      this.providerSelected = provider;
      this.changeStatus();
    },

    changeStatus() {
      this.dialogAdministration = !this.dialogAdministration;
      this.loadProviderList();
    },
    showAddModal() {
      this.dialogAddManagerStatus = !this.dialogAddManagerStatus;
    },

    showEditModal() {
      this.dialogEditManagerStatus = !this.dialogEditManagerStatus;
    },

    getProviderId(providerId) {
      this.selectedProviderId = providerId;
    },

    editStatus(edit) {
      this.editStatusModal = edit;
    }
  }
};
</script>
