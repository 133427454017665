<template>
  <v-dialog v-model="dialogManagerAdm" persistent max-width="600px">
    <v-card class="mx-auto">
      <v-card-title>
        <v-icon left color="primary"> mdi-badge-account-horizontal </v-icon>
        <span class="text-h6">Adicionar administrador do fornecedor </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          :disabled="disableFields"
        >
          <v-text-field
            v-model="adm_form.name"
            label="Nome"
            outlined
            dense
            :rules="nameRules"
            required
            name="administration-name"
          >
          </v-text-field>

          <v-text-field
            v-model="adm_form.cpf"
            label="CPF do Administrador"
            outlined
            dense
            :rules="cpfRules"
            required
            name="administration-cpf"
          >
          </v-text-field>
          <v-text-field
            v-model="adm_form.rg"
            label="RG do Administrador"
            outlined
            dense
            :rules="rgRules"
            required
            name="administration-rg"
          >
          </v-text-field>

          <v-text-field
            v-model="adm_form.email"
            label="E-mail"
            :rules="emailRules"
            outlined
            dense
            required
            name="administration-email"
          >
          </v-text-field>
          <v-text-field
            v-model="adm_form.pass"
            label="Senha"
            :rules="passRules"
            outlined
            dense
            required
            name="administration-pass"
          >
          </v-text-field>
          <v-col md="12" cols="12">
            <quill-editor
              :content="content"
              :options="editorOption"
              :disabled="disableFields"
              @change="onEditorChange($event)"
            />
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue-grey darken-2" text @click="closedDialogAdd">
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="saveFormAdministration"
          :loading="creating"
          :disabled="creating"
          >Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  props: ["selectedProviderId", "editStatusModal"],
  components: {
    quillEditor
  },

  data() {
    return {
      adm_form: {
        name: "",
        email: "",
        pass: "",
        cpf: "",
        rg: "",
        email_signature: "",
        provider_id: ""
      },
      content: "",
      creating: false,
      dialogManagerAdm: true,
      valid: true,
      emailRules: [
        v => !!v || "E-mail obrigatório",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail deve ser válido"
      ],
      nameRules: [v => !!v || "Nome obrigatório"],
      passRules: [v => !!v || "Senha Obrigatoria"],
      cpfRules: [v => !!v || "CPF Obrigatorio"],
      rgRules: [v => !!v || "RG Obrigatorio"],
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
      disableFields: true
    };
  },

  async mounted() {
    if (this.editStatusModal) {
      await this.loadSelectedManager(this.selectedProviderId).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.adm_form = this.selected_Administration.data[0];
      this.adm_form.provider_id = this.selectedProviderId;
      this.content = this.selected_Administration.data[0].email_signature;
    }
    this.disableFields = false;
  },

  computed: {
    ...mapState("provider", [
      "selected_Administration",
      "administration_save",
      "selectedAdministrator"
    ])
  },

  methods: {
    ...mapActions("provider", [
      "saveAdm",
      "updateManager",
      "loadSelectedManager"
    ]),

    onEditorChange(quill) {
      this.content = quill.html;
      this.$emit("changeMessage", {
        message: quill.html,
        index_rule: this.index
      });
    },

    closedDialogAdd() {
      this.statusAddManager = false;
      this.$emit("showAddModal", this.statusAddManager);
    },

    async saveFormAdministration() {
      if (this.$refs.form.validate()) {
        this.adm_form.email_signature = this.content;
        this.adm_form.provider_id = this.selectedProviderId;
        this.creating = true;
        const params = this.adm_form;

        if (this.editStatusModal) {
          await this.updateManager(params).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
        } else {
          await this.saveAdm(params).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
        }

        if (this.administration_save.success) {
          this.$swal({
            icon: "success",
            title: this.administration_save.message,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Continuar cadastrando",
            cancelButtonText: "Listagem",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93",
            showLoaderOnConfirm: false
          }).then(result => {
            if (!result.value) {
              this.closedDialogAdd();
            } else {
              this.$refs.form.resetValidation();
            }
            this.content = "";
            this.adm_form = {
              name: "",
              email: "",
              cpf: "",
              rg: "",
              pass: "",
              email_signature: "",
              provider_id: this.selectedProviderId
            };
          });
          this.creating = false;
        } else {
          this.$swal("Oops ...", this.administration_save.message, "warning");
        }
        this.creating = false;
      }
    }
  }
};
</script>
