<template>
  <v-dialog v-model="openModal" persistent max-width="900px">
    <v-card>
      <v-card-title class="headline">Regras Captura E-mail</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-sheet class="mx-auto mt-2" elevation="4">
            <v-col cols="12">
              <v-col class="cols-md-12" v-if="ruleList.length === 0">
                <v-alert class="mt-5" border="left" dense colored-border type="warning"
                >Este Fornecedor não possui regras de captura de E-mails cadastradas!
                </v-alert>
              </v-col>
              <v-row v-for="(rule, index) in ruleList" :key="index">
                <v-col cols="3">
                  <v-select
                    v-model="rule.field"
                    :items="fields"
                    label="Campo"
                    item-text="name"
                    item-value="value"
                    :rules="defaultRule"
                    dense
                    outlined
                    required
                  ></v-select>
                </v-col>

                <v-col cols="3">
                  <v-select
                    v-model="rule.operator"
                    :items="operators"
                    label="Operador"
                    item-text="name"
                    item-value="value"
                    dense
                    :rules="defaultRule"
                    outlined
                    required
                  ></v-select>
                </v-col>

                <v-col cols="5">
                  <v-text-field
                    v-model="rule.value"
                    label="Valor"
                    :rules="defaultRule"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="d-flex py-4">
                  <v-btn
                    color="error"
                    fab
                    x-small
                    dark
                    title="Remover"
                    @click="removeRule(index)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-col cols="12" class="d-flex justify-end">
                <v-btn
                  @click="addRule"
                  variant="outlined"
                  elevation="5"
                  small
                  color="primary"
                >
                  Adicionar regra
                  <v-icon right> mdi-plus-circle-outline </v-icon>
                </v-btn>
              </v-col>
            </v-col>
          </v-sheet>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancelar</v-btn>
        <v-btn class="primary" @click="submitRules">Prosseguir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    rules_provider: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultRule: [v => !!v || 'Obrigatório'],
      ruleList: [],
      openModal: true,
      fields: [
        {
          name: 'Assunto',
          value: 'subject',
        },
        {
          name: 'Corpo',
          value: 'body'
        },
        {
          name: 'Remetente',
          value: 'from'
        }
      ],
      operators: [
        {
          name: 'Igual',
          value: 'equal'
        },
        // {
        //   name: 'Diferente',
        //   value: 'different'
        // },
        {
          name: 'Contém',
          value: 'contains'
        },
      ],
    };
  },
  mounted() {
    this.ruleList = JSON.parse(JSON.stringify(this.rules_provider));
  },
  methods: {
    addRule() {
      this.ruleList.push({
        field: '',
        operator: '',
        value: '',
      });
    },
    removeRule(index) {
      this.ruleList.splice(index, 1);
    },
    close() {
      this.$emit('close');
      this.openModal = false;
    },
    submitRules() {
      if (this.$refs.form.validate()) {
        this.$emit('change', this.ruleList);
        this.close();
      }
    },
  },
};
</script>

<style scoped>
</style>